<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            sort-by="code"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Variant Juice</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="secondary" dark class="mb-2 mr-2" @click="refreshData" >
                  Refresh
                </v-btn>
                <v-btn color="primary" dark class="mb-2" @click="addNewItem" v-if="$store.state.currentPagePriv.create">
                  New Variant Juice
                </v-btn>
                <v-dialog v-model="dialog" v-if="dialog" max-width="800px" scrollable persistent>
                <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                      New Variant
                    </v-btn>
                </template> -->
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation class="compact-form">
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                              <span>Code</span>
                              <v-text-field v-model="editedItem.code" filled rounded dense hide-details="auto" @keydown.space.prevent
                                readonly placeholder="Auto Generated"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="6">
                              <span>Nama Variant Juice</span>
                              <v-text-field v-model="editedItem.desc" filled rounded dense hide-details="auto" :rules="reqRule"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">Bahan Baku</v-col>
                          <v-col cols="3">Persentase</v-col>
                          <!-- <v-col cols="3">UoM</v-col> -->
                          <v-col cols="2"></v-col>
                        </v-row>
                        <v-row v-for="(obj, idx) in editedItem.details" :key="idx">
                          <v-col cols="4">
                            <v-autocomplete filled rounded dense :items="rawmaterials" item-text="description" item-value="code" v v-model="obj.raw_material_code" hide-details="auto" @change="fillUom(obj)" autocomplete="off" :rules="reqRule" auto-select-first></v-autocomplete>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field type="number" v-model="obj.percentage" filled rounded dense hide-details="auto" :rules="reqRule" suffix="%" @wheel="$event.target.blur()"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="3" :v-show="false">
                            <v-select readonly filled rounded dense :items="uom" v-model="obj.uom" hide-details="auto"></v-select>
                          </v-col> -->
                          <v-col cols="2">
                            <v-icon color="red" class="pt-1" @click="removeVariantDetails(idx)">
                                mdi-minus-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" class="font-weight-bold body-1 pl-4">Total Pesentase</v-col>
                          <v-col cols="3" class="font-weight-bold body-1 pl-4">{{totalPercentage}}%</v-col>
                          <v-col cols="3"></v-col>
                          <v-col cols="2" class="">
                            <v-btn color="primary" outlined text @click="addVariantDetails()">Add</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close()">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="body-1 text-center">Anda akan menghapus variant juice ini?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm" :disabled="okDisabled">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.size`]="{ item }">
              {{item.size}} ml
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" v-if="$store.state.currentPagePriv.update">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" v-if="$store.state.currentPagePriv.delete">
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
            </template>
        </v-data-table>
        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        
        <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
        <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    </div>
</template>

<script>

  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Variant Juice', value: 'desc' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        details: []
      },
      defaultItem: {
      },
      search: '',
      rawmaterials: [],
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
      onProgress: false,
      saved: false,
      saveError: false,
      variantJuices: [],
      okDisabled: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Variant Juice' : 'Edit Variant Juice'
      },
      roles () {
        return this.$store.state.role.objs
      },
      totalPercentage() {
        return (this.editedItem.details.reduce((n, {percentage}) => n + Number(percentage), 0)).toFixed(2)
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      uom () {
        return this.$store.state.rawmaterial.uom
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.rawmaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
        this.variantJuices = (await this.$store.dispatch('getObjs', {apidomain:'variantJuice', parameter:''})).data
        if (this.variantJuices.status=='success') {
          this.items = this.variantJuices.data.filter((item, index, self) =>
            index === self.findIndex(t =>
              t.code === item.code && t.desc === item.desc
            )
          );
        }
      },

      async refreshData() {
        this.initialize()
      },

      addNewItem() {
        this.dialog = true
        this.addVariantDetails()
      },

      fillUom(obj) {
        obj.uom = (this.rawmaterials.find(element=> {
          return element.code == obj.raw_material_code
        })).uom
      },

      addVariantDetails() {
        let details = {raw_material_code: '', percentage: ''}
        this.editedItem.details.push(details)
      },

      removeVariantDetails(idx) {
        console.log(idx)
        this.editedItem.details.splice(idx, 1)
        console.log(this.editedItem.details, idx)
      },
      
      getCategoryName(code) {
        return this.categories.find(element => {
          return element.code == code
        }).category_name;
      },

      getSupplierName(code) {
        return this.suppliers.find(element => {
          return element.code == code
        }).supplier_name;
      },

      async editItem (item) {
        this.onProgress = true
        const vItem = this.variantJuices.data.filter(el => {return el.code==item.code})
        console.log(vItem)
        this.editedItem = {
          code: vItem[0].code,
          desc: vItem[0].desc,
          details: []
        }
        vItem.forEach(element => {
          this.editedItem.details.push({
            raw_material_code: element.raw_material_code,
            percentage: element.percentage
          })
        });
        this.editedIndex = this.items.indexOf(item)
        console.log(this.editedItem)
        this.dialog = true
        this.onProgress = false
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.okDisabled = true
        let url = this.$store.state.config.apiHost + "variantJuice/" + this.editedItem.code
        let response = await this.$axios.delete(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        if (response.data.status=='success') this.items.splice(this.editedIndex, 1)
        this.closeDelete()
        this.okDisabled = false
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({details:[]}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({details:[]}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        await this.$refs.form.validate();
        if (!this.valid) return;
        const payload = {variantJuices:[]}
        this.editedItem.details.forEach(element => {
          payload.variantJuices.push({
            code: this.editedItem.code,
            desc: this.editedItem.desc,
            raw_material_code: element.raw_material_code,
            percentage: element.percentage
          })
        });
        console.log('payload', payload)
        if (this.editedIndex > -1) {
          let url = this.$store.state.config.apiHost + "variantJuice/" + this.editedItem.code
          let response = await this.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          console.log(response)
          if (response.data.status=='success') Object.assign(this.items[this.editedIndex], this.editedItem)
          this.close()          
        } else {
          let url = this.$store.state.config.apiHost + "variantJuice"
          let response = await this.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') this.items.push(this.editedItem)
          this.close()
        }
        this.initialize()
      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>